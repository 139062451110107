import { Card, Col, Row, Typography } from "antd";
import {
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import GBarChart from "../components/chart/GBarChart";
import GThreePieSlice from "../components/chart/GThreePieSlice";
import GPieChart from "../components/chart/GPieChart";
import GVerticalBar from "../components/chart/GVerticalBar";
import GLineChart from "../components/chart/GLineChart";
import {
  getdashVolCount,
  getCountyCateCount,
  getCenterPieChartData,
  getStatusWiseCenterData,
  getPayRecivedChannelData,
  getOisAmtPosBankAmt,
  getYearComparison,
  getMonthTrendData,
  getMatchWisePaymentData,
  getMatchWiseBankPaymentData,
  getActionStatusData,
} from "../util/APIUtils";
import {
  dataForCenterPiechart,
  dataForStatusBarchart,
  stat_barOptions,
  payrecive_channel_data,
  convertOisBankPosAmt,
  yearCompAppCate,
  paymentMatchData,
  bankPaymentMatchData,
  actionStatusData,
} from "../util/ChartDataConverter";
import LoadingIndicator from "../commons/DashboardLoader";

const { TabPane } = Tabs;


function Home() {
  const { Title } = Typography;

  const [activeTab, setActiveTab] = useState("1"); // Manage active tab key
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);

  const [pieData, setPieData] = useState([]);
  const [pieOption, setPieOption] = useState([]);


  const [lineData, setLineData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [vbarData, setvbarData] = useState([]);
  const [vbarOptions, setvabeOptions] = useState([]);

  const [vbarCateData, setvbarCateData] = useState([]);
  const [vbarCateOptions, setvabeCateOptions] = useState([]);

  const [vObarData, setOvbarData] = useState([]);
  const [vObarOptions, setOvabeOptions] = useState([]);

  const [barDataForPaymentDataMatch, setBarDataForPaymentDataMatch] = useState(
    []
  );
  const [
    barOptionsForPaymentDataMatchOptions,
    setBarOptionsForPaymentDataMatchOptions,
  ] = useState([]);

  const [barDataAsPerBankStatment, setBarDataAsPerBankStatment] = useState([]);
  const [barOptionsAsPerBankStatment, setBarOptionsAsPerBankStatment] =
    useState([]);

  const [barDataActionStatus, setBarDataActionStatus] = useState([]);
  const [barOptionsForActionStatus, setBarOptionsForActionStatus] = useState(
    []
  );

  const [thrpieData, setThrPieData] = useState([]);
  const [thrpieOptions, setThrpieOptions] = useState([]);

  const [barData, setbarData] = useState([]);
  const [barOptions, setBarOptions] = useState([]);

  const [volCount, setVolCount] = useState({
    tot_apl_vol: 0,
    rev_gen: 0,
    visa_app_pro: 0,
    pass_app_pro: 0,
    nin_app_pro: 0,
    bvn_app_pro: 0,
  });

  // notification.config({
  //   placement: "top",
  //   top: 70,
  //   duration: 30,
  // });


  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      await getVolAnCount();
      if (isMounted) {
      fetchDataForActiveTab(activeTab);
     
      }
    };
  
    fetchData();
    return () => {
      isMounted = false; // Component is unmounting
      // Optionally cancel ongoing requests or timers here
    };
  }, [activeTab]);

  const getVolAnCount = async () => {
    getdashVolCount();
    try {
      const response = await getdashVolCount();
      if (response === null) {
        setVolCount({});
        
      } else {
        setVolCount(response);

                   }
    } catch (error) {
      handleApiError(error);
      
    }
  };

  const fetchDataForActiveTab = (tabKey) => {
    setLoading(true);

    switch (tabKey) {
      case "1":
        fetchCenterPieChartData();
        break;
      case "2":
        fetchCountyCateCount();
        break;
      case "3":
        fetchStatusWiseCenterData();
        break;
      case "4":
        fetchPayRecivedChannelData();
        break;
      case "5":
        fetchYearComparison();
        break;
      case "6":
        fetchMonthTrendData();
        break;
      case "7":
        fetchOisAmtPosBankAmt();
        fetchMatchWisePaymentData();
        fetchMatchWiseBankPaymentData();
        fetchActionStatusData();
        break;
      default:
        break;
    }
  };

  const fetchCenterPieChartData = () => {
    setLoading(true);
    getCenterPieChartData()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          setPieData([]);
          setPieOption([]);
        } else {
          const secondItems = dataForCenterPiechart(response).map(
            (item) => item[1]
          );
          const firstItems = dataForCenterPiechart(response).map(
            (item) => item[0]
          );
          setPieData(secondItems);
          setPieOption(firstItems);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchCountyCateCount = () => {
    setLoading(true);
    getCountyCateCount()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          setThrPieData([]);
          setThrpieOptions([]);
        } else {
          const result = {};
          response.forEach((item) => {
            if (!result[item.appCategory]) {
              result[item.appCategory] = [];
              result[item.appCategory].push(["Type of Application", "Numbers"]);
            }
            result[item.appCategory].push([item.centerCountry, item.count]);
          });
          const finalResult = Object.keys(result).map((appCategory) => [
            appCategory,
            result[appCategory],
          ]);
          const secondItems = finalResult.map((item) => item[1]);
          const firstItems = finalResult.map((item) => item[0]);
          setThrPieData(secondItems);
          setThrpieOptions(firstItems);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchStatusWiseCenterData = () => {
    setLoading(true);
    getStatusWiseCenterData()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          setbarData([]);
          setBarOptions([]);
        } else {
          setbarData(dataForStatusBarchart(response));
          setBarOptions(stat_barOptions(response));
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchPayRecivedChannelData = () => {
    setLoading(true);
    getPayRecivedChannelData()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          setvbarData([]);
          setvbarCateData([]);
          setvabeOptions({});
          setvabeCateOptions({});
        } else {
          setvbarData(payrecive_channel_data(response)[0]);
          setvbarCateData(payrecive_channel_data(response)[1]);
          setvabeOptions({});
          setvabeCateOptions({});
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchYearComparison = () => {
    setLoading(true);
    getYearComparison()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          setLineData([]);
        } else {
          const fetchedLineData = yearCompAppCate(response);
          setLineData(fetchedLineData);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchMonthTrendData = () => {
    setLoading(true);
    getMonthTrendData()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          console.log("Request failed");
        } else {
          const charts = [
            { title: "BVN applications", data: [] },
            { title: "NIN applications", data: [] },
            { title: "Passport applications", data: [] },
            { title: "Visa applications", data: [] },
          ];

          const uniqueCountries = new Set();

          const header = ["X", "Nigeria", "UAE", "UK"];

          Object.keys(response).forEach((key) => {
            response[key].forEach((item) => {
              const { country, month, count } = item;
              uniqueCountries.add(country);

              let chartIndex = charts.findIndex((chart) => chart.title === key);
              if (chartIndex === -1) {
                charts.push({ title: key, data: [header] });
                chartIndex = charts.length - 1;
              }

              let monthData = charts[chartIndex].data.find(
                (data) => data[0] === month
              );
              if (!monthData) {
                monthData = [month];
                charts[chartIndex].data.push(monthData);
              }

              while (monthData.length < 4) {
                monthData.push(0);
              }

              const countryIndex = charts[chartIndex].data[0].indexOf(country);
              if (countryIndex !== -1) {
                monthData[countryIndex] += count;
              }
            });
          });

          const filteredCharts = charts
            .filter((chart) => chart.data.length > 0)
            .map((chart) => ({
              title: chart.title,
              data: chart.data.map((monthData) => {
                if (monthData.length < 4) {
                  const newData = [...monthData];
                  while (newData.length < 4) {
                    newData.push(0); // Fill missing values with 0
                  }
                  return newData;
                }
                return monthData;
              }),
            }));

          setChartData(filteredCharts);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchOisAmtPosBankAmt = () => {
    setLoading(true);
    const vodata = [
      ["Year", "as per OIS", "as per Payment Gateway", "as per Bank Statement"],
      [2024, 1500, 400, 1000],
    ];
    setOvbarData(vodata);
    const vooptions = {
      chart: {
        title: "OiS Reconciliation $",
        hAxis: {
          title: "X Axis Label",
        },
        vAxis: {
          title: "Y Axis Label",
        },
        colors: ["#333b81", "#005faa", "#0387ad", "#02a88f"],
        bar: { groupWidth: "70%" },
      },
    };
    setOvabeOptions(vooptions);

    getOisAmtPosBankAmt()
      .then((response) => {
        setLoading(false);
        if (response === null) {
          console.log("request failed");
        } else {
          const vodataC = convertOisBankPosAmt(response);

          if (vodataC.length !== 1) {
            setOvbarData(vodataC);
            setOvabeOptions(vooptions);
          } else {
            setOvbarData(vodata);
            setOvabeOptions(vooptions);
          }
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchMatchWisePaymentData = () => {
    setLoading(true);
    getMatchWisePaymentData()
      .then((response) => {
        setLoading(false);
        const matchData = paymentMatchData(response);
        const displayOptions = {
          chart: {
            title: "Payment Gateway Reconciliation  $",
            hAxis: {
              title: "X Axis Label",
            },
            vAxis: {
              title: "Y Axis Label",
            },
            colors: ["#005faa", "#333b81", "#0387ad", "#02a88f"],
            bar: { groupWidth: "70%" },
          },
        };
        setBarOptionsForPaymentDataMatchOptions(displayOptions);

        if (matchData.length !== 1) {
          setBarDataForPaymentDataMatch(matchData);
          setBarOptionsForPaymentDataMatchOptions(displayOptions);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchMatchWiseBankPaymentData = () => {
    setLoading(true);

    getMatchWiseBankPaymentData()
      .then((response) => {
        setLoading(false);

        const matchDataAsPerBank = bankPaymentMatchData(response);
        const displayOptionsToDisplayBankData = {
          chart: {
            title: "Bank Reconciliation  $",
            hAxis: {
              title: "X Axis Label",
            },
            vAxis: {
              title: "Y Axis Label",
            },
            colors: ["#333b81", "#005faa", "#0387ad", "#02a88f"],
            bar: { groupWidth: "70%" },
          },
        };
        setBarOptionsAsPerBankStatment(displayOptionsToDisplayBankData);

        if (matchDataAsPerBank.length !== 1) {
          setBarDataAsPerBankStatment(matchDataAsPerBank);
          setBarOptionsAsPerBankStatment(displayOptionsToDisplayBankData);
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const fetchActionStatusData = () => {
    setLoading(true);

    getActionStatusData()
      .then((response) => {
        setLoading(false);

        const matchResponse = actionStatusData(response);
        const displayOptionsToDisplayActionsData = {
          chart: {
            title: "Action",
            hAxis: {
              title: "X Axis Label",
            },
            vAxis: {
              title: "Y Axis Label",
            },
            colors: ["#333b81", "#005faa", "#0387ad", "#02a88f"],
            bar: { groupWidth: "70%" },
          },
        };
        setBarOptionsForActionStatus(displayOptionsToDisplayActionsData);

        if (matchResponse.length !== 1) {
          setBarDataActionStatus(matchResponse);
          setBarOptionsForActionStatus(displayOptionsToDisplayActionsData);
        } else {
          console.error("Error fetching data:");
        }
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  const handleApiError = (error) => {
    console.error("API error:", error);
    notification.error({
      message: "Error",
      description: "Failed to fetch data from the server.",
    });
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    fetchDataForActiveTab(tabKey);
  };

  const dollor = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const heart = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const cart = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const count = [
    {
      today: "Total Application Volume",
      title: volCount !== undefined ? volCount.tot_apl_vol : 0,
      persent: "2024 YTD",
      icon: profile,
      bnb: "bnb2",
    },
    {
      today: "Total Revenue Generated",
      title:
        volCount && volCount.hasOwnProperty("tot_rev_gen")
          ? '$' + volCount.tot_rev_gen.toLocaleString() // Format number as currency
          : "0",
      persent: currentYear + " YTD",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "VISA Applications Completed",
      title: volCount !== undefined ? volCount.visa_app_pro : 0,
      persent: currentYear + " YTD",
      icon: heart,
      bnb: "bnb2",
    },
    {
      today: "Passport Applications Completed",
      title: volCount !== undefined ? volCount.pass_app_pro : 0,
      persent: currentYear + " YTD",
      icon: cart,
      bnb: "bnb2",
    },
    {
      today: "BVN Applications Completed",
      title: volCount !== undefined ? volCount.bvn_app_pro : 0,
      persent: currentYear + " YTD",
      icon: cart,
      bnb: "bnb2",
    },
    {
      today: "NIN Application Completed",
      title: volCount !== undefined ? volCount.nin_app_pro : 0,
      persent: currentYear + " YTD",
      icon: cart,
      bnb: "bnb2",
    },
  ];


  // Function to generate chart options
  const getChartOptions = (chartType) => {
    // Replace 'set' with a space and trim spaces around 'application'
    const modifiedTitle = chartType
      .replace("set", " ")
      .replace("Application", " Application")
      .trim();

    return {
      title: modifiedTitle,
      hAxis: {
        title: "Month",
      },
      vAxis: {
        title: "Numbers",
      },
      colors: ["#333b81", "#005faa", "#0387ad", "#02a88f"],
    };
  };


    const renderLineCharts = (data, title) => {
          // Split title by space into an array of words
    const words = title.split(' ');

    // Capitalize the first letter of the second word
    if (words.length > 1) {
      words[1] = words[1][0].toUpperCase() + words[1].slice(1);
    }

    // Join the words back into a single string with camelCase format
    const formattedTitle = words.join(' ');

      return (
        <Card bordered={false} className="">
          <GLineChart
            data={data}
            options={{
              title: formattedTitle,
              hAxis: {
                title: 'Country',
              },
              vAxis: {
                title: 'Numbers',
              },
              colors: ['#333b81', '#005faa', '#0387ad', '#02a88f'],
            }}
          />
          <p className="source-text">Source: OIS Data</p>
        </Card>
      );
    };

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
            key={`count_${index}`} 
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Centre-wise view" key="1">
            <LoadingIndicator loading={loading} />
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {!loading &&
                pieData.map((data, index) => (
                  <Col
                  key={`pie_${index}`}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="circle-box">
                      <GPieChart
                        data={pieData[index]}
                        options={{
                          title: pieOption[index],
                          animation: {
                            duration: 1000, // Animation duration in milliseconds
                            easing: "out", // Easing function for the animation
                            startup: true, // Animate on startup
                          },
                          colors: ["#333b81", "#005faa", "#0387ad", "#02a88f"],
                          pieSliceText: "value",
                          enableInteractivity: false,
                        }}
                      />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                ))}
            </Row>
          </TabPane>

          <TabPane tab="Applications completed – Categorywise" key="2">
            <LoadingIndicator loading={loading} />
            {/* Display loading indicator */}
            {!loading && (
              <>
                {/* First Row */}
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                  {thrpieData.slice(0, 2).map((data, index) => (
                    <Col
                    key={`thrpie_${index}`}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={10}
                      className="mb-24"
                    >
                      <Card bordered={false} className="">
                        <GThreePieSlice
                          data={data}
                          options={{
                            title:
                              thrpieOptions[index] + " Applications Processed",
                            is3D: true,
                            colors: [
                              "#333b81",
                              "#005faa",
                              "#0387ad",
                              "#02a88f",
                            ],
                            slices: {
                              4: { offset: 0.2 },
                              12: { offset: 0.3 },
                              14: { offset: 0.4 },
                              15: { offset: 0.5 },
                            },
                          }}
                        />
                        <p className="source-text">Source: OIS Data</p>
                      </Card>
                    </Col>
                  ))}
                </Row>

                {/* Second Row */}
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                  {thrpieData.slice(2).map((data, index) => (
                    <Col
                    key={`thrpie_${index + 2}`}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={10}
                      className="mb-24"
                    >
                      <Card bordered={false} className="">
                        <GThreePieSlice
                          data={data}
                          options={{
                            title:
                              thrpieOptions[index + 2] +
                              " Applications processed",
                            colors: [
                              "#333b81",
                              "#005faa",
                              "#0387ad",
                              "#02a88f",
                            ],
                            is3D: true,
                            slices: {
                              4: { offset: 0.2 },
                              12: { offset: 0.3 },
                              14: { offset: 0.4 },
                              15: { offset: 0.5 },
                            },
                          }}
                        />
                        <p className="source-text">Source: OIS Data</p>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </TabPane>

          <TabPane tab="Center wise application status" key="3">
            <LoadingIndicator loading={loading} />
            {!loading && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {barData.map((data, index) => (
                  <Col
                  key={`bar_${index}`} 
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GBarChart data={data} options={barOptions[index]} />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}

          </TabPane>

          <TabPane tab="Payment Recived $" key="4">
            <LoadingIndicator loading={loading} />
            {!loading && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                <Col sm={24} md={12} lg={12} xl={10} className="mb-24">
                  <Card bordered={true} className="">
                    <GVerticalBar data={vbarData} options={vbarOptions} />
                    <p className="source-text">Source: OIS Data</p>
                  </Card>
                </Col>
                <Col sm={24} md={12} lg={12} xl={10} className="mb-24">
                  <Card bordered={true} className="">
                    <GVerticalBar
                      data={vbarCateData}
                      options={vbarCateOptions}
                    />
                    <p className="source-text">Source: OIS Data</p>
                  </Card>
                </Col>
              </Row>
            )}
          
          </TabPane>

          <TabPane tab="YOY Comparision " key="5">
            <LoadingIndicator loading={loading} />
            {!loading && (
        <Row className="rowgap-vbox" gutter={[24, 0]}>
        {Object.keys(lineData).map((key, index) => (
          <Col   key={`line_${key}`} xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                        {renderLineCharts(lineData[key], `${key} applications`)}

          </Col>
        ))}
      </Row>    
              
            )}      

         
          </TabPane>

          <TabPane tab="Monthly Trends" key="6">
            <LoadingIndicator loading={loading} />
            {!loading && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {chartData.map((chart, index) => (
                  <Col
                  key={`chart_${chart.title}`} 
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GLineChart
                        data={chart.data}
                        options={getChartOptions(chart.title)}
                      />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>

          <TabPane tab="Reconciliation" key="7">
            <LoadingIndicator loading={loading} />
            {!loading && (
              <>
                <Row className="row" gutter={[24, 0]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GVerticalBar data={vObarData} options={vObarOptions} />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GVerticalBar
                        data={barDataForPaymentDataMatch}
                        options={barOptionsForPaymentDataMatchOptions}
                      />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                </Row>
                <Row className="row" gutter={[24, 0]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GVerticalBar
                        data={barDataAsPerBankStatment}
                        options={barOptionsAsPerBankStatment}
                      />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={10}
                    className="mb-24"
                  >
                    <Card bordered={false} className="">
                      <GVerticalBar
                        data={barDataActionStatus}
                        options={barOptionsForActionStatus}
                      />
                      <p className="source-text">Source: OIS Data</p>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Home;
