import {
  Row,
  Col,
  Card  
} from "antd";
import { ACCESS_TOKEN,API_OIS_DATA } from '../constants/index';
import { Button, message, Upload} from 'antd';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';

function Billing() {
 
  const UploadFile = () => { 
    
    const validateExcelFile = (file) => {
      // Check if the file is an XLSX file
      const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    
      if (!isXLSX) {
        message.error('You can only upload XLSX files!');
        return false;
      }
    
      // Check if the file is not empty
      if (file.size === 0) {
        message.error('File is empty!');
        return false;
      }
    
      return true;
    };
    
    
      const handleBeforeUpload = (file) => {
        const isValid = validateExcelFile(file);
        return isValid;
      };
    
         
    
    
      const handleCustomRequest = ({ file }) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          // Your validation logic here (e.g., check for specific columns, no blank cells, etc.)
          
          const columnCount = Object.keys(worksheet).filter(key => /^[A-Z]+[1-9][0-9]*$/.test(key)).reduce((acc, key) => {
            const columnLetter = key.replace(/[0-9]/g, '');
            if (!acc.includes(columnLetter)) {
              acc.push(columnLetter);
            }
            return acc;
          }, []).length;
          if (columnCount !== 16) {
            message.error('Upload valid file');
            return;
          }else{
            console.log('Valid File');
          }
          // Proceed with file upload
          const formData = new FormData();
          formData.append('file', file);
          const authToken = localStorage.getItem(ACCESS_TOKEN);
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${authToken}` // Include authentication token in request headers
            }
          };
          const apiendpoint= API_OIS_DATA;
          axios.put(apiendpoint, formData, config)
          .then(response => {    
            message.success('File uploaded successfully!',response);            
          })
          .catch(error => {
            console.error('Error uploading file:', error);
          });
    
        };
        reader.readAsArrayBuffer(file);
        
      };
   
    return(
    <Upload beforeUpload={handleBeforeUpload}
    customRequest={handleCustomRequest}   
    showUploadList={false}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
    );
  }


  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={16}>
          <Row gutter={[24, 0]}>
           <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">Upload OiS Data</h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <UploadFile/>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        
                      </Col>
                    </Row>
                  </>
                }
              >
               
              </Card>
            </Col>
          </Row>
        </Col>
       
      </Row>
     
    </>
  );
}

export default Billing;
