import React, { useState, useEffect, useCallback } from 'react';
import { Table, Row, Col, Modal, Typography, Dropdown, Menu, Input, message, Button } from 'antd';
import ExportExcelButton from '../commons/ExportExcelButton';
import { DownOutlined } from '@ant-design/icons';
import { getPaymentGatewayDetails, updatePaymentReconsilationData } from '../util/APIUtils'; // Import your API function here



const { Text, Link } = Typography;

const App = () => {
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Fixed page size
  const [comment, setComment] = useState('');
  const [token, setToken] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [fetchNextRecordsVisible, setFetchNextRecordsVisible] = useState(true); // Control visibility of Fetch Next 1000 Records button
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [resolvedRecords, setResolvedRecords] = useState(new Set());

  const fetchDataFromApi = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPaymentGatewayDetails(currentPage, pageSize);

      const newDataSource = response.content.map((item, index) => ({
        ...item,
        key: `${item.id}_${index}`,
      }));
  
      setDataSource(newDataSource);
      setTotalRecords(response.total);
  
      if (response.content.length < pageSize) {
        setFetchNextRecordsVisible(false); // No more records to fetch
      } else {
        setFetchNextRecordsVisible(true); // More records available
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  
  },[currentPage, pageSize]);

  useEffect(() => {
    fetchDataFromApi();
  }, [fetchDataFromApi]);
  
  const handleFetchNextRecords = async () => {
    try {
      setLoading(true);
      const nextPage = currentPage + 1;
      const response = await getPaymentGatewayDetails(nextPage, 1000);
  
      const newDataSource = response.content.map((item) => ({
        ...item,
        key: item.id, // Assign a unique key to each row for Table component
      }));
  
      // Append the new data to the existing dataSource
      setDataSource(prevDataSource => [...prevDataSource, ...newDataSource]);
  
      setTotalRecords(response.total);
      setCurrentPage(nextPage); // Increase the current page number
  
      // Show/Hide button to fetch next 1000 records
      if (response.content.length < pageSize) {
        setFetchNextRecordsVisible(false); // No more records to fetch
      } else {
        setFetchNextRecordsVisible(true); // More records available
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  
  const getMenu = (record) => {
    const menuItems = [];

    if (!record.actionStatus || record.actionStatus === 'NotInitiated') {
      menuItems.push(
        <Menu.Item key="Work In Progress">
          <Link onClick={() => handleMenuClick(record, 'Work In Progress')}>Work In Progress</Link>
        </Menu.Item>,
        <Menu.Item key="Resolved">
          <Link onClick={() => handleMenuClick(record, 'Resolved')}>Resolved</Link>
        </Menu.Item>
      );
    } else if (record.actionStatus === 'Work In Progress') {
      menuItems.push(
        <Menu.Item key="Resolved">
          <Link onClick={() => handleMenuClick(record, 'Resolved')}>Resolved</Link>
        </Menu.Item>
      );
    } 
    return <Menu>{menuItems}</Menu>;
  };

  
  
  const handleResolvedClick = (record) => {
    setSelectedRecord(record); // Set the selected record
    setModalVisible(true); // Show the modal
  };

  const handleWorkInProgressClick = (record) => {
    Modal.confirm({
      title: 'Work In Progress',
      content: (
        <div>
          <p>This record will be marked as Work In Progress.</p>
        </div>
      ),
      onOk() {
        updateRecordStatus(record, 'Work In Progress', ''); // Call function to update status to Work In Progress with blank comment and token
      },
      onCancel() {
        fetchDataFromApi();
      },
      okText: 'Confirm',
      cancelText: 'Cancel',
    });
  };
  
  const updateRecordStatus = async (record, status, comment, token) => {
    try {
      let isAppNum, referenceId;
  
      if (record.application_number) {
        isAppNum = true;
        referenceId = record.application_number;
      } else {
        isAppNum = false;
        referenceId = record.id;
      }
  
      let params = {
        refId_appnum: String(referenceId),
        reson_to_update: comment || '', // Use empty string if comment is falsy
        token: token || '', // Use empty string if token is falsy
        status: status, // Pass the status ('Work In Progress' or 'Resolved')
        isAppNum: isAppNum,
      };
  

      // Call your API to update status
      await updatePaymentReconsilationData(params); // Assuming updatePaymentReconsilationData function exists
      message.success(` ${isAppNum ? 'Application Number' : 'Reference ID'} ${referenceId} is updated to ${status}`);
  

    
    } catch (error) {
      console.error('Error updating record status:', error);
      message.error('Failed to update record status. Please try again.');
    }
  };


  const handleMenuClick = (record, action) => {
    if (action === 'Work In Progress') {
      record.actionStatus = action; // Update actionStatus in the record
      handleWorkInProgressClick(record);
    } else if (action === 'Resolved') {
      record.actionStatus = action; // Update actionStatus in the record
      handleResolvedClick(record);
    }
    setSelectedRecord(record); // Optionally update the selected record state
  };

  const handleExpand = (expanded, record) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);
  };

  const renderExpandedRow = (record) => {
    const nestedColumns = [
      { title: 'Application No', dataIndex: 'application_number', key: 'application_number' },
      { title: 'Application Type', dataIndex: 'application_type', key: 'application_type' },
      { title: 'Payment Date', dataIndex: 'payment_date', key: 'payment_date' },
      { title: 'Payment Amount', dataIndex: 'amount_paid', key: 'amount_paid' },
    ];

    const nestedData = record.oisDetailsSet || [];

    return (
      <Table
        columns={nestedColumns}
        dataSource={nestedData}
        pagination={false} // Disable pagination for nested table
        size="middle"
        bordered
      />
    );
  };

  const renderMatchColumn = (matchstat) => {
    const isNoMatch = matchstat === 'No Match' || matchstat === 'No Match In OIS' || matchstat === 'No Match in Payment Gateway';

    if (isNoMatch) {
      return <Text type="danger">{matchstat}</Text>;
    } else if (matchstat === 'Partial Match') {
      return <Text type="warning">Partial Match</Text>;
    } else if (matchstat === 'Match') {
      return <Text type="success">Match</Text>;
    } else {
      return null;
    }
  };

  const renderActionsColumn = (record) => {
    if (record.matchstat === 'Match' || resolvedRecords.has(record.key)) {
      return null; // Render nothing if the record is resolved or matchstat is Match
    }
  
    // Determine the display text based on actionStatus
    const displayText = record.actionStatus ? record.actionStatus : 'Not Initiated';
  
    const shouldRenderMenu =
      record.matchstat === 'No Match In OIS' ||
      record.matchstat === 'Partial Match' ||
      record.matchstat === 'No Match in Payment Gateway';
  
    if (shouldRenderMenu) {
      return (
        <Dropdown overlay={getMenu(record)} trigger={['click']}>
          <Link onClick={(e) => e.preventDefault()}>
            {displayText} <DownOutlined />
          </Link>
        </Dropdown>
      );
    } else {
      return null; // Return null if no dropdown menu should be rendered
    }
  };

  const handleOk = async () => {
    if (!comment.trim() || !token.trim()) {
      message.error('Comments and Token cannot be empty!');
      return;
    }

    try {
      let isAppNum, referenceId;

      if (selectedRecord.application_number) {
        isAppNum = true;
        referenceId = selectedRecord.application_number;
      } else {
        isAppNum = false;
        referenceId = selectedRecord.id;
      }

      let params = {
        refId_appnum : String(referenceId),
        reson_to_update: comment,
        token: token,
        status: 'Resolved',
        isAppNum: isAppNum,
      };

      // Call your API to mark as resolved
      await updatePaymentReconsilationData(params); // Assuming updatePaymentReconsilationData function exists
      message.success(`${referenceId} ${isAppNum ? 'Application Number' : 'Reference ID'} is updated to Resolved`);


      // Update the resolved records set
      setResolvedRecords(new Set([...resolvedRecords, selectedRecord.key]));
   
      // Refetch data after update
       fetchDataFromApi();

      setModalVisible(false); // Hide the modal
      setComment(''); // Clear the comment state
      setToken(''); // Clear the token state
    } catch (error) {
      console.error('Error resolving record:', error);
      message.error('Failed to resolve record. Please try again.');
    }
  };

  const handleCancel = () => {
    setModalVisible(false); // Hide the modal
    setComment(''); // Clear the comment state
    setToken(''); // Clear the token state
     // Refetch data after update
      fetchDataFromApi();
  };


  const columns = [
    {
      title: 'ID/REFERENCE_ID',
      dataIndex: 'id',
      key: 'id',
      width: 120,
    },
    {
      title: 'Application Number',
      dataIndex: 'application_number',
      key: 'application_number',
      width: 150,
    },
    {
      title: 'OIS Application Data',
      children: [
        {
          title: 'Payment Date',
          dataIndex: 'payment_date',
          key: 'payment_date',
          width: 120,
        },
        {
          title: 'Amount Paid',
          dataIndex: 'amount_paid',
          key: 'amount_paid',
          width: 120,
        },
      ],
    },
    {
      title: 'Payment Gateway',
      children: [
        {
          title: 'Transaction Date',
          dataIndex: 'trans_date',
          key: 'trans_date',
          width: 120,
        },
        {
          title: 'Transaction Amount',
          dataIndex: 'trans_amt',
          key: 'trans_amt',
          width: 120,
        },
      ],
    },
    {
      title: 'Match',
      dataIndex: 'matchstat',
      key: 'matchstat',
      width: 120,
      render: renderMatchColumn,
    },
    {
      title: 'Difference',
      dataIndex: 'amt_diff',
      key: 'amt_diff',
      width: 120,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 150,
      render: renderActionsColumn,
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]} justify="left" className="mb-24">
        <Col>
          <ExportExcelButton data={dataSource} />
        </Col>
      </Row>

      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: renderExpandedRow,
            rowExpandable: (record) => record.oisDetailsSet && record.oisDetailsSet.length > 0,
            expandedRowKeys: expandedRowKeys,
            onExpand: handleExpand,
          }}
          dataSource={dataSource}
          bordered
          size="middle"
          loading={loading}
          pagination={{
            pageSize: 10,
            total: totalRecords,
            showSizeChanger: false, // Hide the pagination size changer dropdown
            showQuickJumper: false, // Hide the quick jumper input
            position: ['bottomRight'], // Position pagination to bottom right
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,   
           
          }}
            className="recon-table"
          footer={() => (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {fetchNextRecordsVisible && (
        <Button type="primary" onClick={handleFetchNextRecords} style={{ marginRight: 8 }}>
          Fetch Next 1000 Records
        </Button>

      )}
    </div>
  
          )}
        />
      </div>
      <Modal
        title="Mark as Resolved"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        okButtonProps={{ disabled: !comment.trim() || !token.trim() }}
        afterClose={() => {
          setComment(''); // Reset comment state after modal closes
          setToken(''); // Reset token state after modal closes
        }}
      >
        {selectedRecord && (
          <>
            <div style={{ marginBottom: '10px' }}>
              {selectedRecord.matchstat === 'No Match' || selectedRecord.matchstat === 'No Match In OIS' ? (
                <Text strong>Reference ID: {selectedRecord.id}</Text>
              ) : (
                <Text strong>Application Number: {selectedRecord.application_number}</Text>
              )}
            </div>

            {/* Input for Comment */}
            <Input
              placeholder="Please enter Comments"
              style={{ marginBottom: '10px' }}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />

            {/* Input for Token */}
            <Input
              placeholder="Please enter Token Number"
              style={{ marginBottom: '10px' }}
              value={token}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />

            <p style={{ marginTop: '10px', marginBottom: '0' }}>
              Are you sure you want to mark this as resolved?
            </p>
          </>
        )}
      </Modal>
    </>
  );
};

export default App;
