
export function dataForCenterPiechart(response) {

  // Initialize an empty object to store results
  const result = {};

  // Iterate through the data array
  response.forEach(item => {
    // If the center_location doesn't exist in result, create it with an empty array
    if (!result[item.center_location]) {
      result[item.center_location] = [];
      result[item.center_location].push(["Type of Application", "Numbers"]);
    }
    // Push an array with appCategory and count into the center_location's array

    result[item.center_location].push([item.appCategory, item.percent]);
  });

  // Convert the object to an array of arrays
  const arrayOfArrays = Object.entries(result);

  return arrayOfArrays;
}

export function dataForStatusBarchart(response) {

  const groupedData = {};
  response.forEach(item => {
    const key = item.appCategory + '-' + item.center_location;
    if (!groupedData[key]) {
      groupedData[key] = {
        "City": item.center_location,
        "Rejected": 0,
        "Completed": 0,
        "In Progress": 0,
        "Applied": 0
      };
    }
    groupedData[key][item.app_status] += item.count;
  });

  // Convert the grouped data object into the desired array format
  const bdata_B = [];
  const bdata_P = [];
  const bdata_N = [];
  const bdata_V = [];
  bdata_B.push(["City", "Rejected", "Completed", "In Progress", "Applied"]);
  bdata_P.push(["City", "Rejected", "Completed", "In Progress", "Applied"]);
  bdata_N.push(["City", "Rejected", "Completed", "In Progress", "Applied"]);
  bdata_V.push(["City", "Rejected", "Completed", "In Progress", "Applied"]);
  for (const key in groupedData) {
    if (key.split("-")[0].startsWith("B")) {
      const entry = groupedData[key];
      const row = ["", entry.Rejected, entry.Completed, entry['In Progress'], entry.Applied];
      bdata_B.push(row);
    }
    if (key.split("-")[0].startsWith("P")) {
      const entry = groupedData[key];
      const row = ["", entry.Rejected, entry.Completed, entry['In Progress'], entry.Applied];
      bdata_P.push(row);
    }
    if (key.split("-")[0].startsWith("N")) {
      const entry = groupedData[key];
      const row = ["", entry.Rejected, entry.Completed, entry['In Progress'], entry.Applied];
      bdata_N.push(row);
    }
    if (key.split("-")[0].startsWith("V")) {
      const entry = groupedData[key];
      const row = ["", entry.Rejected, entry.Completed, entry['In Progress'], entry.Applied];
      bdata_V.push(row);
    }
  }

  return ([bdata_B, bdata_P, bdata_N, bdata_V]);

}

export function stat_barOptions(response) {

  const boptions_B = {
    title: "Center wise  BVN Application status",
    chartArea: { width: "50%" },
    hAxis: {
      title: "BVN Application",
      minValue: 0,
    },
    vAxis: {
      title: "Status",
    },
    colors: ['#333b81', '#005faa', '#0387ad', '#02a88f'],
  };

  const boptions_P = {
    title: "Center wise Passport Application status",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Passport Application",
      minValue: 0,
    },
    vAxis: {
      title: "Status",
    },
    colors: ['#333b81', '#005faa', '#0387ad', '#02a88f'],
  };

  const boptions_N = {
    title: "Center wise NIN Application status",
    chartArea: { width: "50%" },
    hAxis: {
      title: "NIN Application",
      minValue: 0,
    },
    vAxis: {
      title: "Status",
    },
    colors: ['#333b81', '#005faa', '#0387ad', '#02a88f'],
  };

  const boptions_V = {
    title: "Center wise Visa Application status",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Visa Application",
      minValue: 0,
    },
    vAxis: {
      title: "Status",
    },
    colors: ['#333b81', '#005faa', '#0387ad', '#02a88f'],
  };

  return ([boptions_B, boptions_P, boptions_N, boptions_V]);
}

export function payrecive_channel_data(response) {

  const groupedData = {};
  response.PayChannel.forEach(item => {
    const key = item.center_location;
    if (!groupedData[key]) {
      groupedData[key] = {
        "Center": item.center_location,
        "Cash": 0,
        "Pos": 0,
        "Online": 0,
        "Total": 0
      };
    }

    if (item.pay_channel === "POS")
      groupedData[key]["Pos"] += item.amount;
    if (item.pay_channel === "ONLINE")
      groupedData[key]["Online"] += item.amount;
    if (item.pay_channel === "CASH")
      groupedData[key]["Cash"] += item.amount;

    groupedData[key]["Total"] += item.amount;
  });

  // Convert the grouped data object into the desired array format
  const vdata = [];
  vdata.push(["Center", "POS CC", "Online", "POS-Cash", "Total"]);
  for (const key in groupedData) {
    const entry = groupedData[key];
    const row = [entry.Center, entry.Pos, entry.Online, entry.Cash, entry.Total];
    vdata.push(row);
  }
  const groupedData_cat = {};
  response.AppCategory.forEach(item => {
    const key = item.app_type;
    if (!groupedData_cat[key]) {
      groupedData_cat[key] = {
        "Center": item.center_location,
        "BVN": 0,
        "VISA": 0,
        "PASSPORT": 0,
        "NIN": 0,
        "Total": 0
      };
    }
    if (item.app_type === "VISA")
      groupedData_cat[key]["VISA"] += item.amount;
    if (item.app_type === "PASSPORT")
      groupedData_cat[key]["PASSPORT"] += item.amount;
    if (item.app_type === "NIN")
      groupedData_cat[key]["NIN"] += item.amount;
    if (item.app_type === "BVN")
      groupedData_cat[key]["BVN"] += item.amount;

    groupedData_cat[key]["Total"] += item.amount;
  });
  // Convert the grouped data object into the desired array format
  const vdata_cat = [];
  vdata_cat.push(["Center", "BVN", "VISA", "PASSPORT", "NIN", "Total"]);
  for (const key in groupedData_cat) {
    const entry = groupedData_cat[key];
    const row = [entry.Center, entry.BVN, entry.VISA, entry.PASSPORT, entry.NIN, entry.Total];
    vdata_cat.push(row);
  }

  return ([vdata, vdata_cat]);
}

export function yearCompAppCate(data) {
  const fetchData = {
    "BVN": [
      ["x", "2024", "2023"],
      ["UAE", 0, 0],
      ["Lebanon", 10, 5],
      ["USA", 23, 15],
      ["UK", 17, 9]
    ],
    "NIN": [
      ["x", "2024", "2023"],
      ["UAE", 0, 0],
      ["Lebanon", 10, 5],
      ["USA", 23, 15],
      ["UK", 17, 9]
    ],
    "PASSPORT": [
      ["x", "2024", "2023"],
      ["UAE", 0, 0],
      ["Lebanon", 10, 5],
      ["USA", 23, 15],
      ["UK", 17, 9]
    ],
    "VISA": [
      ["x", "2024", "2023"],
      ["UAE", 0, 0],
      ["Lebanon", 10, 5],
      ["USA", 23, 15],
      ["UK", 17, 9]
    ]
  };

  // Function to find index of a specific country in fetchData array
  function findCountryIndex(array, country) {
    return array.findIndex(row => row[0] === country);
  }

  // Loop through JSON data and update counts in fetchData object
  data.forEach(entry => {
    const { app_type, country, year, count } = entry;
    const yearIndex = year === 2023 ? 2 : 1;

    if (!fetchData[app_type]) {
      return; // Skip if app_type is not in the fetchData object
    }

    const countryIndex = findCountryIndex(fetchData[app_type], country);
    if (countryIndex !== -1) {
      fetchData[app_type][countryIndex][yearIndex] += count;
    }
  });

  return fetchData;
}

//Bar chart converters

export function convertOisBankPosAmt(response) {
  const headers = ["Year", "as per OIS", "as per Payment Gateway", "as per Bank Statement"];
  return [headers, ...response.map((item) => [item.year, item.ois_amt, item.pos_amt, item.bank_amt])];
}

export function paymentMatchData(response) {
  const headers = ["Match", "as per Payment Gateway"];
  return [headers, ...response.map((item) => [item.match, item.amount])];
}


export function bankPaymentMatchData(response) {
  const headers = ["Match", "as per Bank Statment"];
  return [headers, ...response.map((item) => [item.match, item.amount])];
}

export function actionStatusData(response) {
  const headers = ["Count", "Action"];
  return [headers, ...response.map((item) => [item.action_status, item.count])];
}