import { Switch, Route, Redirect,withRouter } from "react-router-dom";
import React, { Component } from 'react';
import Home from "./pages/Home";
import ReportPage from "./pages/ReportPage";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Tables from "./pages/Tables";
import Bank from "./pages/Bank";
import Main from "./components/layout/Main";
import Users from "./pages/Users";
import Logs from "./pages/Logs";
import OisData from "./pages/OisData";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { notification } from 'antd';
import { getCurrentUser } from '../src/util/APIUtils';
import { ACCESS_TOKEN ,ACCESS_RIGHTS} from '../src/constants/index';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true,
      user_rights:[]
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    // notification.config({
    //   placement: 'topRight',
    //   top: 70,
    //   duration: 2,
    // });    
  }

  loadCurrentUser() {
    getCurrentUser()
    .then(response => {
      localStorage.setItem(ACCESS_RIGHTS, response.user_rights);
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false,
        use_rights:response.user_rights
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });  
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  


  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_RIGHTS);
    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    notification.success({
      message: 'Recon',
      description: "You're successfully logged out.",
    });

    this.props.history.push("/sign-in");
  }

  handleLogin() {
    notification.success({
      message: 'Recon',
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/dashboard");
  }

  render() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" render={(props) => <SignIn onLogin={this.handleLogin} {...props} />}></Route>
        
        if(this.state.isAuthenticated){         
        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/payg" component={Tables} />
          <Route exact path="/bank" component={Bank} />
          <Route exact path="/oisd" component={OisData} />
          <Route exact path="/rpt" component={ReportPage} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/logs" component={Logs} />
          <Route exact path="/logout" component={this.handleLogout}/>
          <Redirect from="*" to="/dashboard" />
        </Main>
  }
      </Switch>
    </div>
  );
}
}

export default withRouter(App);
