import React from 'react';
import { Button } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportExcelButton = ({ data }) => {
  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Prepare data for sheets
    const matchData = data.filter(item => item.matchstat === "Match");
    const partialMatchData = data.filter(item => item.matchstat.includes("Partial Match"));
    const noMatchData = data.filter(item => item.matchstat.includes("No Match"));

    const matchSheet = createSheet(matchData);
    const partialMatchSheet = createSheet(partialMatchData);
    const noMatchSheet = createSheet(noMatchData);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add sheets to the workbook
    XLSX.utils.book_append_sheet(wb, matchSheet, "Match Data");
    XLSX.utils.book_append_sheet(wb, partialMatchSheet, "Partial Match Data");
    XLSX.utils.book_append_sheet(wb, noMatchSheet, "No Match Data");

    // Convert and save to file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    const fileName = `RECX_${Date.now()}${fileExtension}`;
    saveAs(dataExcel, fileName);
  };

  const createSheet = (data) => {
    const sheetData = data.map(item => {
      const row = {
        ...item,
      };
      if (item.posDetailsSet && item.posDetailsSet.length > 0) {
        // Add posDetailsSet items one below another
        row.posDetailsSet = item.posDetailsSet.map(pos => `Payment Id: ${pos.pid}, Transaction Amount: ${pos.tranAmount}`).join('\n');
      } else if(item.oisDetailsSet && item.oisDetailsSet.length > 0){
        row.oisDetailsSet = item.oisDetailsSet.map(ois => `Reference Id: ${ois.reference_id}, Application Number: ${ois.application_number}, Amount paid: ${ois.amount_paid}`).join('\n');

      }
      return row;
    });
  
    // Convert to sheet format
    const sheet = XLSX.utils.json_to_sheet(sheetData);
  
    return sheet;

  }

  return (
    <Button type="primary" onClick={exportToExcel}>Export to Excel</Button>
  );
};

export default ExportExcelButton;
