import React from 'react';
import { Button } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportReportButton = ({ data }) => {
  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';     
    const matchSheet = XLSX.utils.json_to_sheet(data);  

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add sheets to the workbook
    XLSX.utils.book_append_sheet(wb, matchSheet, "Center Wise");
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataExcel = new Blob([excelBuffer], {type: fileType});    
    const fileName = `RECX_center_${Date.now()}${fileExtension}`;
    saveAs(dataExcel, fileName);
  };

  return (
    <>
    <Button type="primary" onClick={exportToExcel}>Export to Excel</Button>
    </>
  );
};

export default ExportReportButton;
