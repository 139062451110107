import {
  Row,
  Col,
  Card, 
  Avatar,
  Radio  
} from "antd";


import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";


function Profile() {
  

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={profilavatar} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">Sarah Jacob</h4>
                  <p>Accountant</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">Menu 1</Radio.Button>
                <Radio.Button value="b">Menu 2</Radio.Button>
                <Radio.Button value="c">Menu 3</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>

     
    </>
  );
}

export default Profile;
