import React, { useState, useEffect, useCallback } from "react";
import { Table, message, Typography, Dropdown, Menu, Modal, Input,Row,Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  getBankPaymentGatewayDetails,
  updateBankReconsilationData,
} from "../util/APIUtils";
import ExportExcelButton from '../commons/ExportExcelButton';


const { Text, Link } = Typography;
const { confirm } = Modal;

const App = () => {
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [resolvedRecords, setResolvedRecords] = useState(new Set());
  const [comment, setComment] = useState("");
  const [token, setToken] = useState("");
  const [mounted, setMounted] = useState(true); 

  const fetchPaymentGatewayDetails = useCallback(async (currentPage = 1, size = 10) => {
    setLoading(true);
    try {
      const response = await getBankPaymentGatewayDetails(currentPage, size);
      const newDataSource = response.content.map((item) => ({
        ...item,
        key: item.refid, // Use a unique key here, assuming refid is unique
      }));
      if (mounted) {
        setDataSource(newDataSource);
        setTotalRecords(response.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      if (mounted) {
        setLoading(false);
      }
    }
  },[mounted]);



  useEffect(() => {
    fetchPaymentGatewayDetails(1, pageSize);
    return () => {
      setMounted(false);
    };
  }, [fetchPaymentGatewayDetails,pageSize]);

  const renderMatchStatusColumn = (matchstat) => {
    if (matchstat === "No Match") {
      return <Text type="danger">{matchstat}</Text>;
    } else if (matchstat === "Match") {
      return <Text type="success">{matchstat}</Text>;
    } else {
      return null;
    }
  };


  const renderActionsColumn = (record) => {
    if (record.matchstat === 'Match' || resolvedRecords.has(record.key)) {
      return null; // Render nothing if the record is resolved or matchstat is Match
    }
  
    // Determine the display text based on actionStatus
    const displayText = record.actionStatus ? record.actionStatus : 'Not Initiated';

    if (record.actionStatus === 'Resolved') {
      return null; // Render nothing if actionStatus is 'Resolved'
    }
    const shouldRenderMenu =
      record.matchstat === 'No Match';
  
    if (shouldRenderMenu) {
      return (
        <Dropdown overlay={getMenu(record)} trigger={['click']}>
          <Link onClick={(e) => e.preventDefault()}>
            {displayText} <DownOutlined />
          </Link>
        </Dropdown>
      );
    } else {
      return null; // Return null if no dropdown menu should be rendered
    }
  };


  const columns = [
    { title: "REFERENCE_ID", dataIndex: "refid", key: "refid" },
    { title: "Transaction Date", dataIndex: "traDate", key: "traDate" },
    { title: "Credit Amount", dataIndex: "credit", key: "credit" },
    {
      title: "Payment Gateway Amount",
      dataIndex: "pos_total_amt",
      key: "pos_total_amt",
    },
    {
      title: "Match Status",
      dataIndex: "matchstat",
      key: "matchstat",
      render: renderMatchStatusColumn,
    },
    { title: "Difference", dataIndex: "diff", key: "diff" },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: renderActionsColumn,
    },
  ];

  const getMenu = (record) => {
    const menuItems = [];

    if (!record.actionStatus || record.actionStatus === 'NotInitiated') {
      menuItems.push(
        <Menu.Item key="Work In Progress">
          <Link onClick={() => handleMenuClick(record, 'Work In Progress')}>Work In Progress</Link>
        </Menu.Item>,
        <Menu.Item key="Resolved">
          <Link onClick={() => handleMenuClick(record, 'Resolved')}>Resolved</Link>
        </Menu.Item>
      );
    } else if (record.actionStatus === 'Work In Progress') {
      menuItems.push(
        <Menu.Item key="Resolved">
          <Link onClick={() => handleMenuClick(record, 'Resolved')}>Resolved</Link>
        </Menu.Item>
      );
    } 
    return <Menu>{menuItems}</Menu>;
  };

  const handleMenuClick = (record, action) => {
    if (action === "Work In Progress") {
      record.actionStatus = action;
      handleWorkInProgressClick(record);
    } else if (action === "Resolved") {
      record.actionStatus = action;
      handleResolvedClick(record);
    }
    setSelectedRecord(record);
  };

  const handleWorkInProgressClick = (record) => {
    confirm({
      title: "Work In Progress",
      content: <p>This record will be marked as Work In Progress.</p>,
      onOk() {
        updateRecordStatus(record, "Work In Progress", "");
      },
      onCancel() {
        fetchPaymentGatewayDetails();

      },
    });
  };

  const handleResolvedClick = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  const updateRecordStatus = async (record, status, comment) => {
    try {
      const refIdAppNum = record.refid

      const params = {
        refId: String(refIdAppNum),
        reason_to_update: comment || "",
        token: token || "",
        status: status,
      };

      await updateBankReconsilationData(params);

      record.actionStatus = status;

      message.success(
        `${refIdAppNum} ${
          record.refid + " Reference ID"
        } is updated to ${status}`
      );

      if (mounted) {
         await fetchPaymentGatewayDetails(); // Only update state if component is still mounted
      }
    } catch (error) {
      console.error("Error updating record status:", error);
      message.error("Failed to update record status. Please try again.");
    }
  };

  const handleOk = async () => {
    if (!comment.trim() || !token.trim()) {
      message.error("Comments and Token cannot be empty!");
      return;
    }

    try {
      const refIdAppNum = selectedRecord.refid;

      const params = {
        refId: String(refIdAppNum),
        reason_to_update: comment,
        token: token,
        status: "Resolved",
      };

      await updateBankReconsilationData(params);

      message.success(
        `${refIdAppNum} ${
          selectedRecord.refIdAppNum + " Reference ID"
        } is updated to Resolved`
      );

      if (mounted) {
        setResolvedRecords(new Set([...resolvedRecords, selectedRecord.key]));
        setModalVisible(false);
        setComment("");
        setToken("");

      await  fetchPaymentGatewayDetails();

      }
    } catch (error) {
      console.error("Error resolving record:", error);
      message.error("Failed to resolve record. Please try again.");
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    setComment("");
    setToken("");
    fetchPaymentGatewayDetails();
  };

  const renderExpandedRow = (record) => {
    const nestedColumns = [
      { title: "Reference Id", dataIndex: "pid", key: "pid" },
      {
        title: "Transaction Amount",
        dataIndex: "tranAmount",
        key: "tranAmount",
      },
      { title: "Transaction Date", dataIndex: "tranDate", key: "tranDate" },
    ];

    const nestedData = record.posDetailsSet.map((item, index) => ({
      ...item,
      key: index, // Assign a unique key based on index or use a unique identifier if available
    }));
    return (
      <>
        <Table
          columns={nestedColumns}
          dataSource={nestedData}
          pagination={false}
          size="middle"
          bordered
        />
      </>
    );
  };


  return (
    <>
    <Row gutter={[24, 0]} justify="left" className="mb-24">
        <Col>
          <ExportExcelButton data={dataSource} />
        </Col>
      </Row>

      <Table
        columns={columns}
        expandedRowRender={renderExpandedRow}
        dataSource={dataSource}
        loading={loading}
        bordered
        size="middle"
        scroll={{ x: "max-content" }}
        pagination={{
          total: totalRecords,
          pageSize: pageSize,
          onShowSizeChange: (current, size) => setPageSize(size),
        }}
        className="recon-table"
      />

      <Modal
        title="Mark as Resolved"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        okButtonProps={{ disabled: !comment.trim() || !token.trim() }}
        afterClose={() => {
          setComment(""); // Reset comment state after modal closes
          setToken(""); // Reset token state after modal closes
        }}
      >
        {selectedRecord && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <Text strong>Reference ID: {selectedRecord.refid}</Text>
            </div>

            {/* Input for Comment */}
            <Input
              placeholder="Please enter Comments"
              style={{ marginBottom: "10px" }}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />

            {/* Input for Token */}
            <Input
              placeholder="Resolved By"
              style={{ marginBottom: "10px" }}
              value={token}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />

            <p style={{ marginTop: "10px", marginBottom: "0" }}>
              Are you sure you want to mark this as resolved?
            </p>
          </>
        )}
      </Modal>
    </>
  );
};

export default App;
