import React, { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [isLoggedIn] = useState(true); // Replace with your authentication logic


  if (!isLoggedIn) {
    return (
      <div>
        <NavLink to="/sign-in">Login</NavLink>
      </div>
    );
  }

  const dashboard = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>
  );

  const billing = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>
  );

  const rtl = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>
  );
  
  const signup = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>
  );


  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item className="menu-item-header" key="1">
          Dashboard
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/dashboard">
            <span className="icon" style={{ background: page === "dashboard" ? color : "" }}>
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="3">
          Reconciliation Engine
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/payg">
            <span className="icon" style={{ background: page === "billing" ? color : "" }}>
              {billing}
            </span>
            <span className="label">OIS == Payment Gateway</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/bank">
            <span className="icon" style={{ background: page === "billing" ? color : "" }}>
              {billing}
            </span>
            <span className="label">Payment Gateway == Bank</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/rpt">
            <span className="icon" style={{ background: page === "rtl" ? color : "" }}>
              {rtl}
            </span>
            <span className="label">Center Reports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="7">
          Activity Logs
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/logs">
            <span className="icon">{signup}</span>
            <span className="label">Logs</span>
          </NavLink>
        </Menu.Item>
        </Menu>
    </>
  );
}

export default Sidenav;
