import React from "react";
import { Chart } from "react-google-charts";
const GBarChart = ({data,options}) => {
  return (
        <div>
      <Chart
        chartType="BarChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
      </div>
    );
  }

  export default GBarChart;
  