import {
  API_BASE_URL,
  ACCESS_TOKEN,
} from "../constants/index";

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function payg_upload(formData) {
  alert("Loading............");
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/me",
    method: "GET",
  });
}

export function getdashVolCount() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/getvolcount",
    method: "POST",
  });
}

export function getCountyCateCount() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/appcoucat",
    method: "POST",
  });
}

export function getCenterPieChartData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/appcencat",
    method: "POST",
  });
}

export function getStatusWiseCenterData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/appcenstatcat",
    method: "POST",
  });
}

export function getPayRecivedChannelData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/paychcentwise",
    method: "POST",
  });
}

export function getOisAmtPosBankAmt() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/oisamt",
    method: "POST",
  });
}

export function getYearComparison() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/yearcatecocount",
    method: "POST",
  });
}

export function getLogs() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/logs",
    method: "POST",
  });
}

export function getPaymentGatewayDetails(page, size) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/posrecondtl`,
    method: "POST",
    data: {
      page: page,
      size: size,
    },
  });
}

export function updatePaymentReconsilationData(params) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const queryParams = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
  const requestUrl = `${API_BASE_URL}/resolve?${queryParams}`;

  return request({
    url: requestUrl,
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: params,
  });
}

export function getBankPaymentGatewayDetails(page, size) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/bankrcondtl`,
    method: "POST",
    data: {
      page: page,
      size: size,
    },
  });
}

export function getCenterWiseData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/centerwisepos`,
    method: "POST",
  });
}

export function getMonthTrendData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/mnthtrend`,
    method: "POST",
  });
}

export function getMatchWisePaymentData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/pgmatchchart`,
    method: "POST",
  });
}

export function getMatchWiseBankPaymentData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/bankmatchchart`,
    method: "POST",
  });
}

export function getActionStatusData() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/actionstatcount`,
    method: "POST",
  });
}

export function updateBankReconsilationData(params) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const requestObject = {
    url: `${API_BASE_URL}/resolvebank`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };


  return request(requestObject);

}
