import { Divider, List, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { getLogs } from '../util/APIUtils';

const Log = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLogs();
        if (response !== null) {
          const formattedData = response.map(item => [item.timeStamp, item.log_text]);
          setData(formattedData);
        } else {
          console.error('API returned null response');
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 
  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <>
      <Divider orientation="left">Recent Activities</Divider>
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text mark>{item[0]}</Typography.Text> {item[1]}
          </List.Item>
        )}
      />
    </>
  );
};

export default Log;
