export const API_BASE_URL = 'http://139.162.230.117:8085/api';
export const API_BATCH_RUN = 'http://139.162.230.117:8085/run/uploadf';
export const API_BANK_RUN ="http://139.162.230.117:8085/run/bankrecon";
export const API_CENTER_DATA="http://139.162.230.117:8085/api/centerwisepos";
export const API_OIS_DATA="http://139.162.230.117:8085/run/job"
export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_RIGHTS =[2,5,7];

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;


