import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Typography, notification } from "antd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { ACCESS_TOKEN ,ACCESS_RIGHTS} from '../../../src/constants/index';

const { Text } = Typography;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: transparent;
`;

const LogoutButton = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #262626;
  text-decoration: none;
  margin-left: 12px;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

function Header() {

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_RIGHTS);

    // Notify user about successful logout
    notification.success({
      message: 'Recon',
      description: "You're successfully logged out.",
    });

    // Redirect to sign-in page
    window.location.replace("/sign-in");
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HeaderContainer>
      <LogoutButton to="#" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 8 }} />
        <Text strong className="label">
          Logout
        </Text>
      </LogoutButton>
    </HeaderContainer>
  );
}

export default Header;
