import React from 'react';
import { Spin } from 'antd';

const LoadingIndicator = ({ loading }) => {
  if (!loading) return null; // Render nothing if loading is false

  return (
    <div className="loading-indicator">
      <Spin size="large" />
    </div>
  );
};

export default LoadingIndicator;
