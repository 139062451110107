import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'antd';
import ExportReportButton from '../commons/ExportReportButton';
import { getCenterWiseData } from '../util/APIUtils';

const columns = [
  {
    title: 'Center location',
    dataIndex: 'center_location',
    key: 'center_location', 
  },
  {
    title: 'Amount as per OIS',
    dataIndex: 'ois_amt',
    key: 'ois_amt',
  },
  {
    title: 'Amount as per Payment Gateway',
    dataIndex: 'payg_amt',
    key: 'payg_amt',  
  },
  {
    title: 'Amount as per Bank',
    dataIndex: 'bank_amt',
    key: 'bank_amt',
  }
];

const App = () => {  
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getCenterWiseData();
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      fetchData();
    }
  };

  return (
    <>
      <Row gutter={[24, 0]} justify="left" className='mb-24'>
        <Col>
          <ExportReportButton data={tableData} />
        </Col>
      </Row>
      
      <Table
        columns={columns}
        dataSource={tableData.map((item, index) => ({ ...item, key: index }))} 
        pagination={false}
        loading={loading}
        onScroll={handleScroll}
        className="recon-table"
      />
    </>
  );
};

export default App;
